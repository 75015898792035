body {
  background: #575757;
}

.alert {
  text-align: center;
  text-transform: uppercase;
}

// Progressbar
progress[value] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 15px;
}

/*Styles progressbar value color*/
progress[value]::-webkit-progress-value {
  background-image:
     -webkit-linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0,0, 0, .1) 66%, transparent 66%),
     -webkit-linear-gradient(top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)),
     -webkit-linear-gradient(left, #bb1818, #2fa10d);
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}
/*Styles progressbar color*/
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

#project {
  text-transform: capitalize;
}

h4, p {
  display: inline-block;
}

.p-left {
  padding-left: 10px;
  font-size: 18px;
}

.green {
  color: green;
}

.red {
  color: red;
}
