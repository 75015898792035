section.upload-container {
  position: relative;
  height: 100vh;
  .jumbotron {
    height: 540px;
    margin-top: 17vh;
    box-shadow: 0px 0px 10px;
    h4 {
      width: 120px;
    }
    p {
      font-size: 18px;
    }
    #project {
      display: inline-block;
    }
    .info {
      padding-top: 20px;
      width: 100%;
      font-size: 14px;
      display: inline-block;
    }
  }
}
